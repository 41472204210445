(function ($) {
  $(function () {


    /*
     * Navbar fixed 
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top');
      } else {
        $('.site-header').removeClass('navbar-fixed-top');
      }
    });


    /*
     * Counter function
     */
    $(".counter").counterUp({
      delay: 20,
      time: 3000,
    });

    function setNavigation() {
      const sections = document.querySelectorAll("section");
      const navigationLinks = document.querySelectorAll(".site-header ul li a");

      sections.forEach((section) => {
        const top = section.offsetTop - 100;
        const height = section.clientHeight;
        const id = section.getAttribute("id");

        if (window.scrollY >= top && window.scrollY < top + height) {
          navigationLinks.forEach((link) => {
            link.classList.remove("active");
            if (link.getAttribute("href") === `#${id}`) {
              link.classList.add("active");
            }
          });
        }
      });
    }

    // Postavljamo "scroll" događaj kako bi se funkcija izvršavala tokom skrolovanja
    window.addEventListener("scroll", setNavigation);

    // Pozivamo funkciju jednom kako bismo postavili aktivnu sekciju prilikom učitavanja stranice
    setNavigation();

  //  /*
  //    * Scroll on click
  //    */

   const onScrollID = (_this, offsetValue) => {
    // e.preventDefault();
    // target element id
    var id = _this.attr('href');

    // target element
    var $id = $(id);
    if ($id.length === 0) {
      return; 
    }

    var pos = $id.offset().top + offsetValue;

    // animated top scrolling
    $('body, html').animate({
      scrollTop: pos
    }); 
  }

  $('#primary-menu-mobie li a').on('click', function (e) {
    const _this = $(this)
    onScrollID(_this, 0);
  })

  $(document).on('click', 'a.navbar-brand, .navbar a[href^="#"], .scroll-down', function (e) {
    const _this = $(this)
    onScrollID(_this, -50);
  });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      // $('body').toggleClass('fixedPosition');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /* 
     * Owl carousel
     */
    $('.owl-testimonials').owlCarousel({
      margin: 20,
      items: 1,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: false,
      dots: true,
      // navText: ["<i class='dr-arrow-left'></i>", "<i class='dr-arrow-right'></i>"],
      // navContainer: '#customNav',

    });



    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Open modal
     */

    $('#open-modal').on('click', function (e) {
      e.preventDefault();
      $('#modal').modal('show');
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    $('#play-video-btn').on('click', function () {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    })

    window.onYouTubeIframeAPIReady = function () {
      var videoModules = document.querySelectorAll('.video');
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);
    }

    function initializeVideoModule(videoModule) {
      var player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            if (isEnded) {
              player.destroy();
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModule(videoModule);
            }
          }
        }
      });
    }

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });



    /*
     * Mmenu light 
     */
    var menu = new MmenuLight(
      document.querySelector('#my-menu'),
      'all'
    );
    if (menu) {
      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        theme: 'light',
        title: 'Meni'
      });

      var drawer = menu.offcanvas({
        position: 'right'
      });

      //  Open the menu.
      document.querySelector('#nav-icon')
        .addEventListener('click', evnt => {
          evnt.preventDefault();
          drawer.open();
        });

      $(".mob-menu-close").click(function () {
        drawer.close();
      });
    }


  });
})(jQuery);